import { apiOptions, apiRequest } from "../utils";
import { API_V1 } from "../constants";
import { BASE_URL } from "../constants";

export const InventoryService = {
  GetPartners: async (Tokens, params) => {
    const getPartnersUrl = `${BASE_URL}${API_V1}/admin/partners`;
    const options = await apiOptions({
      url: getPartnersUrl,
      method: "get",
      Tokens,
      params,
    });

    return apiRequest(options);
  },

  GetInventory: async (Tokens, params) => {
    const getInventoriesUrl = `${BASE_URL}${API_V1}/admin/inventories`;
    const options = await apiOptions({
      url: getInventoriesUrl,
      method: "get",
      Tokens,
      params,
    });

    return apiRequest(options);
  },
  EditInventory: async (Tokens, product_id, partner_id, params) => {
    const getInventoriesUrl = `${BASE_URL}${API_V1}/admin/inventories/${product_id}/${partner_id}`;
    console.log(getInventoriesUrl, 'getInventoriesUrl');

    const options = await apiOptions({
      url: getInventoriesUrl,
      method: "put",
      Tokens,
      data: params,
    });

    return apiRequest(options);
  },

  CreateInventory: async (Tokens, data) => {
    const getInventoriesUrl = `${BASE_URL}${API_V1}/admin/inventories`;

    const options = await apiOptions({
      url: getInventoriesUrl,
      method: "post",
      Tokens,
      data,
    });

    return apiRequest(options);
  },
  getProductsByCategory: async (Tokens, params) => {
    const getInventoriesUrl = `${BASE_URL}${API_V1}/user/products/category/`;

    const options = await apiOptions({
      url: getInventoriesUrl,
      method: "get",
      Tokens,
      params
    });

    return apiRequest(options);
  },
  GetPartnersId: async (Tokens, params) => {
    const getInventoriesUrl = `${BASE_URL}${API_V1}/admin/partners`;

    const options = await apiOptions({
      url: getInventoriesUrl,
      method: "get",
      Tokens,
      params
    });

    return apiRequest(options);
  },


};
