import {apiOptions,apiRequest} from "../utils";
import { API_V1 } from "../constants";
import { BASE_URL } from "../constants";
export const ReportsListDetails={
    getReportList : async(Tokens,params)=>{
       
        const itemURL=`${ BASE_URL}${ API_V1 }/admin/orderreport`;
        const options = await apiOptions({
            url:itemURL,
            method:"get", 
            params,
            Tokens
      
        });
        return apiRequest(options);
        
    },
    getReportPickList : async(Tokens,params)=>{
        const itemURL=`${ BASE_URL}${ API_V1 }/admin/orders/report/picklist`;
        const options = await apiOptions({
            url:itemURL,
            method:"get", 
            params,
            Tokens
      
        });
        return apiRequest(options);
        
    },

    getAppInstallList : async(Tokens,params)=>{
        
        const itemURL = `${BASE_URL}${API_V1}/admin/reports/appinstall`;
        const options = await apiOptions({
            url:itemURL,
            method:'get',
            Tokens,
            params
        })
        return apiRequest(options)
    },
    downloadReport : async(Tokens,params,url)=>{
        
        const itemURL = url;
        const options = await apiOptions({
            url:itemURL,
            method:'get',
            Tokens,
            params
        })
        return apiRequest(options)
    },
    
getCroCommentsData:async(Tokens,params)=>{
    const itemURL = `${BASE_URL}${API_V1}/admin/reports/cro_comments`;
    const options = await apiOptions({
        url:itemURL,
        method:'get',
        Tokens,
        params
    })
    return apiRequest(options)
},
getRevenueReferrer : async(Tokens,params)=>{
        
    const itemURL = `${BASE_URL}${API_V1}/admin/reports/revenue/referrer`;
    const options = await apiOptions({
        url:itemURL,
        method:'get',
        Tokens,
        params
    })
    return apiRequest(options)
},
getDiseaseCaseType : async(Tokens,params)=>{
        
    const itemURL = `${BASE_URL}${API_V1}/admin/reports/disease`;
    const options = await apiOptions({
        url:itemURL,
        method:'get',
        Tokens,
        params
    })
    return apiRequest(options)
},
}
// export const ReportsPickListDetails={
    
// }